import React from "react";
import Container from "../../components/chat-channel/Container";
import Layout from "../../components/chat-channel/Layout";
import RegisterSection from "../../components/chat-channel/ChannelHeader";
import RightImageWithContentFeature from "../../components/chat-channel/RightImageWithContent";
import LeftImageWithContent from "../../components/chat-channel/LeftImageWithContent";
import ArrowRightICon from "../../components/common/Icons";
import TitleAndMetaTags from "../../components/common/TitleAndHeader";
import { RequestForm } from "../../components/form";

const topImage = require("../../assets/images/trigger/trigger_header.png");
export const icon1 = require("../../assets/images/trigger/trigger_sec_1_icon.png");
export const icon2 = require("../../assets/images/trigger/trigger_sec_2_icon.png");
export const icon3 = require("../../assets/images/trigger/trigger_sec_3_icon.png");

const section_one = require("../../assets/images/trigger/trigger_sec_1.png");
const section_two = require("../../assets/images/trigger/trigger_sec_2.png");
const section_three = require("../../assets/images/trigger/trigger_sec_3.png");

const featureContent = [
  {
    icon: icon1,
    header: "Event Trigger",
    content:
      "Set up event triggers to auto-respond to app-related events instantly. For various service based, lifecycle based or other critical events, you never miss a beat to take quick actions via workflow automations. ",
    url: null,
    link: null,
    image: section_one
  },
  {
    icon: icon2,
    header: "Polling Trigger",
    content:
      "Remove manual efforts by using Polling triggers which can repeatedly check for changes periodically, at regular intervals in third party apps to trigger workflow automations.",
    image: section_two,
    url: null,
    link: null
  },
  {
    icon: icon3,
    header: "Webhook Trigger",
    content:
      "Make work faster by using Webhook triggers that can be set up easily on Workativ’s no-code platform, integrating your existing apps for real-time data synchronization and instant notifications of changes in data. ",
    image: section_three,
    url: null,
    link: null
  }
];

export default function FeatureHomePage() {
  return (
    <>
      <TitleAndMetaTags
        title="No-code workflow automation platform with multiple trigger options. Using polling, event triggers or webhooks to automate your workflows."
        description="Integrate business apps and create intelligent workflows with triggers or webhook on Workativ's intuitive, user-friendly, no-code automation platform"
        keywords={["IT Helpdesk Chatbot", "HR Chatbot", "Service Desk Chatbot"]}
        ogImage={topImage}
      />
      <Container>
        <section className="workflow_automation_wrapper trigger_page">
          <Layout backgroundColor={"bg_header_trigger"} logoFor="AUTOMATE">
            <RegisterSection
              rightImage={topImage}
              altImage={
                " Automate your workplace process with workflows using application triggers"
              }
              backgroundColor={"bg_header_trigger"}
            >
              <div class="coming_soon_wrapper">
                <span class="coming_soon">Coming soon</span>
              </div>
              <RegisterSection.FormWithContent>
                <RegisterSection.MainHeader>
                  Automate your workplace process with workflows using
                  application triggers
                </RegisterSection.MainHeader>
                <RegisterSection.SubHeader>
                  Connect your apps and launch workflows via event or polling
                  based triggers. Workativ requires no coding to add triggers
                  and webhooks to automate workflows between your favourite
                  apps.
                </RegisterSection.SubHeader>
              </RegisterSection.FormWithContent>
            </RegisterSection>{" "}
            {featureContent.map((feature, index) => {
              if (index % 2 !== 0) {
                return (
                  <div className="support_chatbot">
                    <RightImageWithContentFeature
                      image={feature.image}
                      altImage={feature.header}
                    >
                      <RightImageWithContentFeature.Header>
                        <div className="icon-top-img">
                          <img src={feature.icon} alt="icon" />{" "}
                        </div>
                        <h3>{feature.header}</h3>
                      </RightImageWithContentFeature.Header>

                      <RightImageWithContentFeature.SubHeader>
                        <p
                          dangerouslySetInnerHTML={{ __html: feature.content }}
                        />
                      </RightImageWithContentFeature.SubHeader>
                      <RightImageWithContentFeature.Link>
                        {feature.link && (
                          <div className="card_link_landing">
                            <a href={feature.url} className="url_manipulation">
                              {feature.link}
                              <span className="arrow_svg_link">
                                <ArrowRightICon />
                              </span>
                            </a>
                          </div>
                        )}
                      </RightImageWithContentFeature.Link>
                    </RightImageWithContentFeature>
                  </div>
                );
              }
              return (
                <LeftImageWithContent
                  image={feature.image}
                  altImage={feature.header}
                >
                  <LeftImageWithContent.HeaderIcon>
                    <div className="icon-top-img">
                      <img src={feature.icon} alt="icon" />{" "}
                    </div>
                  </LeftImageWithContent.HeaderIcon>

                  <LeftImageWithContent.MainHeader>
                    {feature.header}
                  </LeftImageWithContent.MainHeader>
                  <LeftImageWithContent.SubHeader>
                    <p dangerouslySetInnerHTML={{ __html: feature.content }} />
                  </LeftImageWithContent.SubHeader>
                  <LeftImageWithContent.Link>
                    {feature.link && (
                      <div className="card_link_landing">
                        <a href={feature.url} className="url_manipulation">
                          {feature.link}
                          <span className="arrow_svg_link">
                            <ArrowRightICon />
                          </span>
                        </a>
                      </div>
                    )}
                  </LeftImageWithContent.Link>
                </LeftImageWithContent>
              );
            })}
            <RequestForm isFooterForm={true} />
          </Layout>
        </section>
      </Container>
    </>
  );
}
